import React, { useState, useEffect } from 'react';
import {
  Badge,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  Collapse,
} from '@material-ui/core';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import EmojiEventsOutlinedIcon from '@material-ui/icons/EmojiEventsOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import InsertChartOutlinedOutlinedIcon from '@material-ui/icons/InsertChartOutlinedOutlined';
import SettingsApplicationsOutlinedIcon from '@material-ui/icons/SettingsApplicationsOutlined';
import OutlinedFlagRoundedIcon from '@material-ui/icons/OutlinedFlagRounded';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartOutlined';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import PieChartOutlinedIcon from '@material-ui/icons/PieChartOutlined';
import NewspaperIcon from '@material-ui/icons/BookOutlined';
import NoteOutlinedIcon from '@material-ui/icons/NoteOutlined';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { ReactComponent as InfraStructureIcon } from '../../assets/imgs/infrastucture-icon.svg';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { menuCss } from './menuCss';
import { ideaActions } from '../../actions';
import config from '../../config';

export function MenuAdmin({ toggleDrawer, userId, pendingIdeas }) {
  const classes = menuCss();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [openSubMenuPreferences, setOpenSubMenuPreferences] = useState(false);

  const isStepFlux = config.SHOW_IDEAS_BY_STEP_FLUX;
  const reportLinkStatusorSteps =  isStepFlux ? '/report/ideas-by-step' : '/report/ideas-status';
  useEffect(() => {
    dispatch(ideaActions.getPendingIdeas());
  }, [dispatch]);

  function handleReportsMenu() {
    setOpenSubMenu(!openSubMenu);
  };
  function handlePreferencesMenu() {
    setOpenSubMenuPreferences(!openSubMenuPreferences);
  };

  return (
    <List className={classes.list}>
      <ListItem
        button
        onClick={toggleDrawer}
        component={Link}
        to="/"
        className={clsx(
          classes.listItem,
          location.pathname === '/'
            ? classes.backgroundActived
            : 'inherit'
        )}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <HomeOutlinedIcon
            className={
              location.pathname === '/'
                ? classes.colorActived
                : classes.colorDisabled
            }
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              className={clsx(
                classes.typography,
                location.pathname === '/'
                  ? classes.colorActived
                  : classes.colorDisabled
              )}
            >
              {t('feed')}
            </Typography>
          }
        />
      </ListItem>

      <ListItem
        button
        onClick={toggleDrawer}
        component={Link}
        to="/my-ideas"
        className={clsx(
          classes.listItem,
          location.pathname === '/my-ideas'
            ? classes.backgroundActived
            : 'inherit'
        )}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <EmojiObjectsOutlinedIcon
            className={
              location.pathname === '/my-ideas'
                ? classes.colorActived
                : classes.colorDisabled
            }
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              className={clsx(
                classes.typography,
                location.pathname === '/my-ideas'
                  ? classes.colorActived
                  : classes.colorDisabled
              )}
            >
              {t('minhas_ideias')}
            </Typography>
          }
        />
      </ListItem>
{/* 
      <ListItem
        button
        onClick={toggleDrawer}
        component={Link}
        to={`/wallet/${userId}`}
        className={clsx(
          classes.listItem,
          location.pathname === `/wallet/${userId}`
            ? classes.backgroundActived
            : 'inherit'
        )}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <AccountBalanceWalletOutlinedIcon
            className={
              location.pathname === `/wallet/${userId}`
                ? classes.colorActived
                : classes.colorDisabled
            }
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              className={clsx(
                classes.typography,
                location.pathname === `/wallet/${userId}`
                  ? classes.colorActived
                  : classes.colorDisabled
              )}
            >
              {t('meus_pontos')}
            </Typography>
          }
        />
      </ListItem> */}

      {
        config.SHOW_MENU_OPTION_RANKING && <ListItem
          button
          onClick={toggleDrawer}
          component={Link}
          to="/ranking"
          className={clsx(
            classes.listItem,
            location.pathname === '/ranking'
              ? classes.backgroundActived
              : 'inherit'
          )}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <EmojiEventsOutlinedIcon
              className={
                location.pathname === '/ranking'
                  ? classes.colorActived
                  : classes.colorDisabled
              }
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                className={clsx(
                  classes.typography,
                  location.pathname === '/ranking'
                    ? classes.colorActived
                    : classes.colorDisabled
                )}
              >
                {t('ranking')}
              </Typography>
            }
          />
        </ListItem>
      }
    { 
      <ListItem
        button
        onClick={toggleDrawer}
        component={Link}
        to="/challenges"
        className={clsx(
          classes.listItem,
          location.pathname === '/challenges'
            ? classes.backgroundActived
            : 'inherit'
        )}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <OutlinedFlagRoundedIcon
            className={
              location.pathname === '/challenges'
                ? classes.colorActived
                : classes.colorDisabled
            }
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              className={clsx(
                classes.typography,
                location.pathname === '/challenges'
                  ? classes.colorActived
                  : classes.colorDisabled
              )}
            >
              {t('desafios')}
            </Typography>
          }
        />
      </ListItem>}
      {
        config.SHOW_MENU_OPTION_PORTFOLIO && <ListItem
        button
        onClick={toggleDrawer}
        component={Link}
        to="/portfolio"
        className={clsx(
          classes.listItem,
          location.pathname === '/portfolio'
            ? classes.backgroundActived
            : 'inherit'
        )}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <BusinessCenterOutlinedIcon
            className={
              location.pathname === '/portfolio'
                ? classes.colorActived
                : classes.colorDisabled
            }
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              className={clsx(
                classes.typography,
                location.pathname === '/portfolio'
                  ? classes.colorActived
                  : classes.colorDisabled
              )}
            >
              {t('portfolio')}
            </Typography>
          }
        />
      </ListItem> 
      }

      {/* <ListItem
        button
        onClick={toggleDrawer}
        component={Link}
        to="/archive-ideas"
        className={clsx(
          classes.listItem,
          location.pathname === '/archive-ideas'
            ? classes.backgroundActived
            : 'inherit'
        )}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <FolderIcon 
            className={
              location.pathname === '/archive-ideas' 
                ? classes.colorActived
                : classes.colorDisabled
            }
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              className={clsx(
                classes.typography,
                location.pathname === '/archive-ideas'
                  ? classes.colorActived
                  : classes.colorDisabled
              )}
            >
              {t('arquivo_de_ideas')}
            </Typography>
          }
        />
      </ListItem>
      <ListItem
        button 
        onClick={toggleDrawer}
        component={Link} 
        to={`/premiar-page`}
        className={clsx(
          classes.listItem,
          location.pathname === '/premiar-page'
            ? classes.backgroundActived
            : 'inherit'
        )}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <ShoppingCartIcon 
            className={
              location.pathname === '/premiar-page' 
                ? classes.colorActived
                : classes.colorDisabled
            }
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              className={clsx(
                classes.typography,
                location.pathname === '/premiar-page'
                  ? classes.colorActived
                  : classes.colorDisabled
              )}
            >
              {t('troca_de_pontos')}
            </Typography>
          }
        />
      </ListItem> */}
      <Divider className={classes.divider} />

      <ListItem
        button
        onClick={toggleDrawer}
        component={Link}
        to="/dashboard"
        className={clsx(
          classes.listItem,
          location.pathname === '/dashboard'
            ? classes.backgroundActived
            : 'inherit'
        )}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <PieChartOutlinedIcon
            className={
              location.pathname === '/dashboard'
                ? classes.colorActived
                : classes.colorDisabled
            }
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              className={clsx(
                classes.typography,
                location.pathname === '/dashboard'
                  ? classes.colorActived
                  : classes.colorDisabled
              )}
            >
              {t('dashboard')}
            </Typography>
          }
        />
      </ListItem>

      <ListItem
        button
        onClick={toggleDrawer}
        component={Link}
        to="/curation"
        className={clsx(
          classes.listItem,
          location.pathname === '/curation'
            ? classes.backgroundActived
            : 'inherit'
        )}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <AssignmentTurnedInOutlinedIcon
            className={
              location.pathname === '/curation'
                ? classes.colorActived
                : classes.colorDisabled
            }
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              className={clsx(
                classes.typography,
                location.pathname === '/curation'
                  ? classes.colorActived
                  : classes.colorDisabled
              )}
            >
              {t('curadoria')}
            </Typography>
          }
        />
        <Badge badgeContent={pendingIdeas} max={1000} overlap="circular" classes={{ badge: classes.badgeColor }} />
      </ListItem>

      <ListItem
        button
        onClick={toggleDrawer}
        component={Link}
        to="/articles"
        className={clsx(
          classes.listItem,
          location.pathname === '/articles'
            ? classes.backgroundActived
            : 'inherit'
        )}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <NewspaperIcon
            className={
              location.pathname === '/articles'
                ? classes.colorActived
                : classes.colorDisabled
            }
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              className={clsx(
                classes.typography,
                location.pathname === '/articles'
                  ? classes.colorActived
                  : classes.colorDisabled
              )}
            >
              {t('artigos')}
            </Typography>
          }
        />
        {/* Adicione um Badge ou qualquer outro elemento adicional, se necess�rio */}
      </ListItem>
      <ListItem
        button
        onClick={toggleDrawer}
        component={Link}
        to="/external-contents"
        className={clsx(
          classes.listItem,
          location.pathname === '/external-contents'
            ? classes.backgroundActived
            : 'inherit'
        )}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <NoteOutlinedIcon
            className={
              location.pathname === '/external-contents'
                ? classes.colorActived
                : classes.colorDisabled
            }
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              className={clsx(
                classes.typography,
                location.pathname === '/external-contents'
                  ? classes.colorActived
                  : classes.colorDisabled
              )}
            >
              {t('conteudos')}
            </Typography>
          }
        />
      </ListItem>

      <ListItem
        button
        onClick={toggleDrawer}
        component={Link}
        to="/users"
        className={clsx(
          classes.listItem,
          location.pathname === '/users'
            ? classes.backgroundActived
            : 'inherit'
        )}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <PeopleOutlinedIcon
            className={
              location.pathname === '/users'
                ? classes.colorActived
                : classes.colorDisabled
            }
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              className={clsx(
                classes.typography,
                location.pathname === '/users'
                  ? classes.colorActived
                  : classes.colorDisabled
              )}
            >
              {t('usuarios')}
            </Typography>
          }
        />
      </ListItem>
      <ListItem
        button
        onClick={toggleDrawer}
        component={Link}
        to="/infrastructure"
        className={clsx(
          classes.listItem,
          location.pathname === '/infrastructure'
            ? classes.backgroundActived
            : 'inherit'
        )}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <InfraStructureIcon
            className={
              location.pathname === '/infrastructure'
                ? classes.colorActived
                : classes.colorDisabled
            }
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              className={clsx(
                classes.typography,
                location.pathname === '/infrastructure'
                  ? classes.colorActived
                  : classes.colorDisabled
              )}
            >
              {t('infraestrutura')}
            </Typography>
          }
        />
      </ListItem>
      {/* 
      <ListItem
        button 
        onClick={toggleDrawer}
        component={Link} 
        to="/infrastructure"
        className={clsx(
          classes.listItem,
          location.pathname === '/infrastructure'
            ? classes.backgroundActived
            : 'inherit'
        )}
      >
        <ListItemIcon className={classes.listItemIcon}>
        <svg src={InfrastuctureIcon} alt="infrastucture-icon" style={{ height: 20, width: 20 }} />

    
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              className={clsx(
                classes.typography,
                location.pathname === '/infrastructure'
                  ? classes.colorActived
                  : classes.colorDisabled
              )}
            >
              {t('infraestrutura')}
            </Typography>
          }
        />
      </ListItem> */}

      <ListItem
        button
        onClick={handleReportsMenu}
        className={clsx(
          classes.listItem,
          classes.subMenu,
          [reportLinkStatusorSteps,
            '/report/ideas-financial-return',
            '/report/colaborator'
          ].includes(location.pathname)
            ? classes.backgroundActived
            : 'inherit'
        )}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <InsertChartOutlinedOutlinedIcon
            className={
              [reportLinkStatusorSteps,
                '/report/ideas-financial-return',
                '/report/colaborator'
              ].includes(location.pathname)
                ? classes.colorActived
                : classes.colorDisabled
            }
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              className={clsx(
                classes.typography,
                [reportLinkStatusorSteps,
                  '/report/ideas-financial-return',
                  '/report/colaborator'
                ].includes(location.pathname)
                  ? classes.colorActived
                  : classes.colorDisabled
              )}
            >
              {t('relatorios')}
            </Typography>
          }
        />
        {openSubMenu ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openSubMenu} timeout="auto" unmountOnExit>
        <List>
          <ListItem
            button
            onClick={toggleDrawer}
            component={Link}
            to={reportLinkStatusorSteps}
            className={classes.subItem}
          >
            <ListItemText
              primary={
                <Typography
                  className={clsx(
                    classes.typography,
                    location.pathname === reportLinkStatusorSteps
                      ? classes.colorActived
                      : classes.colorDisabled
                  )}
                >
                  {config.SHOW_IDEAS_BY_STEP_FLUX ? t('etapas_das_ideias') : t('status_das_ideias')}
                </Typography>
              }
            />
          </ListItem>

          <ListItem
            button
            onClick={toggleDrawer}
            component={Link}
            to="/report/ideas-by-evaluated-step-decision"
            className={classes.subItem}
          >
            <ListItemText
              primary={
                <Typography
                  className={clsx(
                    classes.typography,
                    location.pathname === '/report/ideas-by-evaluated-step-decision'
                      ? classes.colorActived
                      : classes.colorDisabled
                  )}
                >
                  {t('etapas_por_decisao')}
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            button
            onClick={toggleDrawer}
            component={Link}
            to="/report/ideas-with-evaluated-steps"
            className={classes.subItem}
          >
            <ListItemText
              primary={
                <Typography
                  className={clsx(
                    classes.typography,
                    location.pathname === "/report/ideas-with-evaluated-steps"
                      ? classes.colorActived
                      : classes.colorDisabled
                  )}
                >
                  {t('etapas_avaliadas')}
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            button
            onClick={toggleDrawer}
            component={Link}
            to="/report/ideas-status-pending-managers"
            className={classes.subItem}
          >
            <ListItemText
              primary={
                <Typography
                  className={clsx(
                    classes.typography,
                    location.pathname === '/report/ideas-status-pending-managers'
                      ? classes.colorActived
                      : classes.colorDisabled
                  )}
                >
                  {t('ideias_pendetes')}
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            button
            onClick={toggleDrawer}
            component={Link}
            to="/report/ideas-financial-return"
            className={classes.subItem}
          >
            <ListItemText
              primary={
                <Typography
                  className={clsx(
                    classes.typography,
                    location.pathname === '/report/ideas-financial-return'
                      ? classes.colorActived
                      : classes.colorDisabled
                  )}
                >
                  {t('custos_e_retorno')}
                </Typography>
              }
            />
          </ListItem>

          <ListItem
            button
            onClick={toggleDrawer}
            component={Link}
            to="/report/colaborator"
            className={classes.subItem}
          >
            <ListItemText
              primary={
                <Typography
                  className={clsx(
                    classes.typography,
                    location.pathname === '/report/colaborator'
                      ? classes.colorActived
                      : classes.colorDisabled
                  )}
                >
                  {t('lista_de_colaboradores')}
                </Typography>
              }
            />
          </ListItem>
        <ListItem
            button
            onClick={toggleDrawer}
            component={Link}
            to="/report/colaborator-access-by-month"
            className={classes.subItem}
          >
            <ListItemText
              primary={
                <Typography
                  className={clsx(
                    classes.typography,
                    location.pathname === '/report/colaborator-access-by-month'
                      ? classes.colorActived
                      : classes.colorDisabled
                  )}
                >
                  {t('acessos_mensais')}
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            button
            onClick={toggleDrawer}
            component={Link}
            to="/report/colaborator-access"
            className={classes.subItem}
          >
            <ListItemText
              primary={
                <Typography
                  className={clsx(
                    classes.typography,
                    location.pathname === '/report/colaborator-access'
                      ? classes.colorActived
                      : classes.colorDisabled
                  )}
                >
                  {t('acessos_de_colaboradores')}
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            button
            onClick={toggleDrawer}
            component={Link}
            to="/report/managers-list"
            className={classes.subItem}
          >
            <ListItemText
              primary={
                <Typography
                  className={clsx(
                    classes.typography,
                    location.pathname === '/report/managers-list'
                      ? classes.colorActived
                      : classes.colorDisabled
                  )}
                >
                  {t('lista_de_gestores')}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Collapse> 
      <ListItem
        button
        onClick={handlePreferencesMenu}
        className={clsx(
          classes.listItem,
          classes.subMenu,
            ['/preferences/personalized-classification',
              '/preferences/evaluation-teams',
              '/preferences/general-evaluation'
          ].includes(location.pathname)
            ? classes.backgroundActived
            : 'inherit'
        )}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <SettingsApplicationsOutlinedIcon
            className={
                ['/preferences/personalized-classification',
                  '/preferences/evaluation-teams',
                  '/preferences/general-evaluation'
              ].includes(location.pathname)
                ? classes.colorActived
                : classes.colorDisabled}
          ></SettingsApplicationsOutlinedIcon>
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              className={clsx(
                classes.typography,
                ['/preferences/personalized-classification',
                    '/preferences/evaluation-teams',
                    '/preferences/general-evaluation'
                ].includes(location.pathname)
                  ? classes.colorActived
                  : classes.colorDisabled
              )}
            >
              {t('preferencias')}
            </Typography>
          }
        />
        {openSubMenuPreferences ? <ExpandLess /> : <ExpandMore />}
      </ListItem> 
      <Collapse in={openSubMenuPreferences} timeout="auto" unmountOnExit>
        <List>
          <ListItem
            button
            onClick={toggleDrawer}
            component={Link}
            to="/preferences/personalized-classification"
            className={classes.subItem}
          >
            <ListItemText
              primary={
                <Typography
                  className={clsx(
                    classes.typography,
                    location.pathname === '/preferences/personalized-classification'
                      ? classes.colorActived
                      : classes.colorDisabled
                  )}
                >
                  {t('classificacao_das_ideias')}
                </Typography>
              }
            />
          </ListItem>
          {config.SHOW_PERSONALIZED_EVALUATION_CRUD_FORM && <ListItem
            button
            onClick={toggleDrawer}
            component={Link}
            to='/preferences/general-evaluation'
            className={classes.subItem}
          >
            <ListItemText
              primary={
                <Typography
                  className={clsx(
                    classes.typography,
                    location.pathname === '/preferences/general-evaluation'
                      ? classes.colorActived
                      : classes.colorDisabled
                  )}
                >
                  {t('avaliacao_geral')}
                </Typography>
              }
            />
          </ListItem>}
          <ListItem
            button
            onClick={toggleDrawer}
            component={Link}
            to="/preferences/evaluation-teams"
            className={classes.subItem}
          >
            <ListItemText
              primary={
                <Typography
                  className={clsx(
                    classes.typography,
                    location.pathname === '/preferences/evaluation-teams'
                      ? classes.colorActived
                      : classes.colorDisabled
                  )}
                >
                  {t('equipes_de_avaliacao')}
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            button
            onClick={toggleDrawer}
            component={Link}
            to="/help-center-edit"
            className={classes.subItem}
          >
            <ListItemText
              primary={
                <Typography
                  className={clsx(
                    classes.typography,
                    location.pathname === '/help-center-edit'
                      ? classes.colorActived
                      : classes.colorDisabled
                  )}
                >
                  {t('central_de_ajuda')}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Collapse>
    </List>
  );
}
